* {
  background-color: rgb(24, 26, 27);
  color: rgb(137, 138, 139);
  font-size: 1.2rem;
}

h1, h2, h3, h4, h5, h6 {
  color: rgb(243, 243, 243);
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: normal;
}

a {
  color: rgb(214, 197, 45);
}

a:hover {
  text-decoration: underline;
}

.sub-heading {
  color: rgb(162, 162, 162);
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "poppins", helvetica, arial;
  font-family: 'Roboto Slab', serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

iframe {
  width: 100%;
  height: 1000px;
}