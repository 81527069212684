.projectcard-box p {
  font-size: 1rem;
}

.projectcard-box {
  padding: 100px;
  min-height: 200px !important;
  height: 200px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
}

.projectcard-box:hover {
  border: 1px solid rgb(112, 113, 114); 
  border: 1px solid rgb(214, 197, 45);
}

.projectcard-box:hover .open-icon { 
  color: rgb(214, 197, 45);
}

.projectcard-box:hover * { 
  fill: rgb(214, 197, 45);
}

.projectcard-innerbox {
  position: relative;
  width: 100%;
  height: 80%;
  overflow-y: hidden;
}

.techbadge {
  margin-top: 0px;
  display: inline;
  margin-right: 10px;
  padding: 2px 6px 2px 6px;
  border: 1px solid rgb(112, 113, 114);
}

.projectcard-box .sub-heading {
  color: rgb(182, 182, 182);
}

.showcase-heading {
  color: rgb(212, 212, 212) !important;
}

.open-icon {
  position: absolute;
  right: 0px;
  top: 10px;
}

.github-icon {
  position: absolute;
  right: 30px;
  top: 11px;
  font-size: 1.4rem;
}

/* Write code here for hamburger menu and shit */
@media(max-width: 600px) {
  .projectcard-box {
    padding: 100px;
    min-height: 200px !important;
    height: 200px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid rgb(112, 113, 114); 
  }

  .github-icon {
    position: absolute;
    right: 30px;
    top: 11.5px;
  }
}